@import "~styles/variables.scss";

.app-header {
  height: 109px;
  padding: 0 24px;
  flex-shrink: 0;
  display: flex;
  gap: 1rem;
  align-items: center;
  position: sticky;
  top: 0;
  border-bottom: 1px solid;
  border-bottom-color: rgba(68, 79, 86, 0.1);
  // z-index: 9998;
  // transition: border-bottom-color .2s;

  // z-index: 10001;
  // border-bottom: 1px solid transparent;

  .version-number {
    position: absolute;
    right: 0.5rem;
    top: 0;
    opacity: 0.4;
  }

  .menu-button {
    width: 26px;
    height: 26px;
    margin-left: 6px;
    cursor: pointer;

    .icon {
      color: $text-lighten;
    }
  }

  .menu-button-separator {
    margin-left: 18px;
    width: 1px;
    height: 31px;
    background-color: $additional1-10;
  }

  .header-title {
    margin-left: 32px;
    color: $primary;
  }

  .notification-button {
    margin: 0 12px;
    height: 25px;

    svg {
      width: 25px;
    }
  }

  .account-and-status {
    margin-left: auto;
    display: flex;
    gap: 1rem;
    align-items: center;
    flex-grow: 1;
    justify-content: end;

    .account-button {
      width: 26px;
      height: 26px;
      background-color: #ff1945;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;

      .icon {
        color: white;
        width: 18px;
        height: 18px;
      }
    }
    
    .settings-button {
      margin-right: 12px;
      height: 25px;
      cursor: pointer;

      svg {
        width: 25px;
      }
    }

    .syncing-status {
      .anticon {
        margin-right: 0.5rem;
      }
    }

    .online-status {
      display: flex;
      gap: 0.5rem;
      align-items: center;

      .status-indicator {
        width: 26px;
        height: 26px;
        border-radius: 50%;
        border: 1px solid #21ad37;
        display: inline-block;
        position: relative;
      }

      &.online {
        .status-indicator {
          background-color: #baf9ba;
        }
      }

      &.offline {
        .status-indicator {
          background-color: #f8506f;
          border-color: darken(#f8506f, 30);
        }
      }

      .cloudPulse {
        font-size: 25px;
        color: #21ad37;
        margin: 0 auto;
        animation-name: stretch;
        animation-duration: 1.25s;
        animation-timing-function: ease-out;
        animation-direction: alternate;
        animation-iteration-count: infinite;
        animation-play-state: running;
      }

      @keyframes stretch {
        0% {
          transform: scale(0.75);
          border-radius: 100%;
        }
        50% {
          transform: scale(0.85);
        }
        100% {
          transform: scale(1.05);
        }
      }
    }
  }
}
