@import "~styles/variables.scss";

.item-drawer {
  // .ant-drawer-content-wrapper {
  //   width: 550px !important;
  // }

  &.item-drawer-floor {
    .ant-drawer-content-wrapper {
      width: 480px !important;
    }
    .ant-drawer-body {
      padding-top: 0;
      margin-top: -1rem;
    }
    .group-fields {
      display: flex;
      flex-direction: column;

      .form-element {
        margin-bottom: 1.5rem;
      }
    }
  }

  .form-element {
    &.form-element-hidden {
      display: none;
    }
  }

  .created-on {
    display: block;
    margin-top: 0.8rem;
    opacity: 0.7;
  }

  .asset-type {
    margin-bottom: 1rem;
  }

  .open-asset-type-drawer {
    text-decoration: underline;
    cursor: pointer;
    margin-left: 0.3rem;

    &:hover {
      color: $primary;
    }
  }
}
