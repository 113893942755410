@import "~styles/variables.scss";

.ant-drawer {
  .ant-drawer-title {
    font-size: 16px;
    color: $secondary;
    font-weight: 400;
  }

  .ant-drawer-mask {
    background: hsla(0, 0%, 100%, 0.77);
  }

  .ant-drawer-content-wrapper {
    width: 617px !important;
    min-width: unset;
    max-width: unset;
    box-shadow: unset !important;

    .ant-drawer-content {
      padding: 1.3rem;
      padding-right: 1rem;
      padding-top: 1rem;
      background-color: $drawer-background;
      border-left: 1px solid $additional1-10;
    }
  }

  .ant-drawer-header {
    border: unset;
    background-color: unset;
  }

  h2.ant-typography {
    font-size: 16px !important;
    font-weight: 400;
    color: $additional1 !important;
    margin-top: 1.5rem;
  }

  .ant-form {
    .site-collapse-custom-collapse {
      .ant-collapse-header {
        padding: 12px 0px;
      }
    }
  }

  .form-group {
    .ant-collapse {
      background-color: transparent;
    }

    .site-collapse-custom-collapse {
      .ant-collapse-header {
        padding: 12px 0px;
      }

      .ant-collapse-content .ant-collapse-content-box {
        padding-left: 0px;
      }
    }

    .wide-group-fields {
      display: flex;
      flex-direction: column;
      grid-column: span 2;

      .form-element {
        margin-bottom: 1.5rem;
      }
    }

    .tags-and-condition-fields {
      grid-column: span 2;

      .ant-form-item-control-input-content {
        display: grid;
        grid-template-columns: 252px 252px;
        grid-column-gap: 27px;
      }
    }

    .group-fields {
      display: grid;
      grid-template-columns: 252px 252px;
      grid-column-gap: 27px;
      grid-row-gap: 0;
    }

    .ant-col {
      width: 100%;
      max-width: unset !important;
      flex: unset;
    }

    .ant-form-item {
      margin-bottom: 0rem;
    }
  }

  .form-element {
    margin-bottom: 0.5rem;
    position: relative;

    label + * {
      margin-top: 0.2rem;
    }
  }

  label {
    &.mandatory::after {
      display: inline-block !important;
      margin-left: 4px !important;
      color: #ff1945 !important;
      font-size: 14px !important;
      line-height: 1 !important;
      content: "*" !important;
    }
    &.disabled {
      opacity: 0.25;
    }
  }

  .ant-form-item-control-input-content {
    display: flex;
    flex-direction: column;

    & > * {
      width: 100%;
    }

    .ant-select-selector {
      border: 1px solid $additional1-20 !important;
      box-shadow: unset !important;
      width: 100%;
      border-radius: 4px !important;
    }

    .ant-input-number,
    .ant-input {
      border-radius: 4px !important;
      border-color: $additional1-20;
    }

    .ant-tag-checkable:hover {
      color: inherit;
    }

    .ant-tag-checkable-checked {
      background-color: #fafafa !important;
      border: 1px solid #d9d9d9;
      border-radius: 2px;
      white-space: nowrap;
      line-height: 20px;
      color: inherit;
      opacity: 1;
    }
  }

  .ant-form-item-with-help {
    position: relative;

    .ant-col > div:first-child {
      position: relative;
      top: 1.5rem;
    }

    .ant-form-item-explain-error {
      position: absolute;
      top: 0rem;
    }
  }

  .ant-form-item-explain-error {
    font-size: 12px;
  }

  .form-buttons {
    display: flex;
    justify-content: center;
    gap: 1.5rem;
    align-items: center;
    margin-top: 2rem;
  }

  .form-errors {
    border: 1px solid $primary;
    border-radius: 4px;
    padding: 1rem;
    width: 100%;
    margin-top: 2rem;
    position: relative;

    h3.ant-typography {
      font-size: 14px;
      color: $primary;
    }

    .close-form-errors-button {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      color: $primary;
      cursor: pointer;
    }

    .error-item {
      font-size: 12px;
      color: $primary;
      display: block;
      &:not(:last-child) {
        margin-bottom: 0.3rem;
      }
    }
  }
}

.ant-tree-treenode {
  width: 100%;
}

.ant-input-number-handler-wrap {
  opacity: 1 !important;
}

.ant-tree-node-selected {
  width: 100%;
  display: inline-block;
  background-color: unset !important;
  &::before {
    height: 16px;
    width: 16px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 10px;
    top: 5px;
    border-radius: 100px;
    border: 1px solid rgba(68, 79, 86, 0.1);
    pointer-events: none;
    content: "\2714";
    color: #fff !important;
    background-color: #ff1945;
  }
}
