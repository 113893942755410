@import "~styles/variables.scss";

.drawer-photo-section {
  margin-bottom: 1rem;

  .form-element {
      display: flex;
      flex-direction: column;

    .ant-form-item-label{
      text-align: left;
    }
  }

  .ant-form-item-control-input{ 
    width: 530px;
  }

  .photo-input {
    display: none !important;
  }

  .photo-list-container {
    margin-top: 1rem;

    .carousel .control-dots {
      top: 0 !important;
      bottom: unset !important;
    }

    .image-item {
      padding-bottom: 1rem;
      position: relative;

      button {
        height: 2rem;
        padding: 0;
        border-radius: 0;
        position: absolute;
        bottom: 1rem;
        left: 50%;
        transform: translateX(-50%);
        width: 100%;
        background-color: $secondary;
        color: $background;
      }

      img {
        max-width: 100%;
      }
    }
  }
}

.upload-button {
  text-decoration: underline;
  cursor: pointer;
  margin-left: 0.3rem;

  &:hover {
    color: $primary;
  }
}