.input-incrreaser {
  background-color: transparent !important;
  color: black;
  border-width: 0px !important;
  width: 75px !important;
  
  &:focus {
    box-shadow: none !important;
  }
}

