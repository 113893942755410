@import "~styles/variables.scss";

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.spinner-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  animation: fade-in 0.5s 1 linear;
  animation-fill-mode: forwards;

  .spinner {
    width: 12rem;
    height: 12rem;
    background-color: $additional2;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: $border-radius;

    .anticon {
      color: $primary;
      font-size: 2.5rem;

      animation: rotation 1s infinite linear;
    }
  }
}
