@import "~styles/variables.scss";

.app {
  display: flex;
  flex-direction: column;
  height: 100vh;
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  overflow-y: hidden;

  .app-content {
    flex-grow: 1;
    display: flex;
    overflow: auto;

    .page-content {
      flex-grow: 1;
      height: 100%;
      padding-top: 16px;
      padding-left: 20px;
      padding-right: 24px;
      width: 67%;
      // max-width: 75%;
    }
  }

  .row-actions {
    display: flex;
    gap: 0.3rem;

    .icon {
      width: 24px;
      height: 24px;
    }
  }
}

.column-title {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.row-actions {
  display: flex;
  gap: 0.3rem;

  svg {
    width: 24px;
    height: 24px;
  }
}

.barcode-prefix-group {
	display: grid;
	grid-template-columns: 2fr 3fr;
	column-gap: 5px;
}

.filterIcon {
  width: 1.25rem;
  height: 1.25rem;
  margin-right: 5px;
  position: relative;
  top: 3px;
}

.filterHeaderIcon {
  width: 1rem;
  height: 1rem;
  top: 0;
}